// import React from "react";
// import { Routes, Route,Navigate } from "react-router-dom";
// import Home from "../pages/Home";
// import Login from "../pages/Login";
// import AboutUS from "../pages/AboutUS";
// import ContactUs from "../pages/ContactUs";
// import Signup from "../pages/Signup";
// import FAQ from "../pages/FAQ";
// import Services from "../pages/Services";
// import TermsAndCondition from "../pages/TermsAndCondition";
// import Courses from "../pages/Courses";
// import Career from "../pages/Career/Career";
// import Policy from "../pages/Policy";
// import PageNotFound from "../pages/404/PageNotFound";
// import Blogs from "../pages/Blogs/Blogs";
// import CompTIAsinglePage from "../pages/SinglePage/CompTIA/CompTIAsinglePage"


// import ReviewPage from "../pages/review/ReviewPage";
// import BookPage from "../pages/ebook/BookPage";
// import LandingPage from "../pages/landingPage/LandingPage";
// const AllRoute = () => {
//   const token = localStorage.getItem("token")
//   console.log(token)
//   return (
//     <Routes>
//       <Route path="/review-page" element={<ReviewPage />} />
//       <Route path="/" element={<Home />} />
//       {/* <Route path="/ebook-page" element={<BookPage />}/> */}
//        <Route path="/ebook-page" element={token ? <BookPage /> : <Navigate to="/login" replace />} /> 

//        <Route path="/login" element={!token ? <Login /> : <Navigate to="/" replace />} />
// <Route path="/signup" element={!token ? <Signup /> : <Navigate to="/" replace />} />

//       <Route path="/about-us" element={<AboutUS />} />
//       <Route path="/contact-us" element={<ContactUs />} />
//       <Route path="/frequently-asked-questions" element={<FAQ />} />
//       <Route path="/our-services" element={<Services />} />
//       <Route path="/Terms-and-Conditions" element={<TermsAndCondition />} />

//      <Route path="/Courses-details" element={token ? <Courses />  : <Navigate to="/login" replace />} />


//       <Route path="/Career-details" element={<Career />} />
//       <Route path="/Our-Policies" element={<Policy />} />
//       <Route path="/Our-Blogs" element={<Blogs />} />
//       <Route path="*" element={<PageNotFound />} />

//       <Route path="/CompTIA-single-page" element={<CompTIAsinglePage />} />

//       <Route path="/home" element={<LandingPage />} />
//     </Routes>
//   );
// };

// export default AllRoute;

// Updated by Saurav
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import Login from "../pages/Login";
import AboutUS from "../pages/AboutUS";
import ContactUs from "../pages/ContactUs";
import Signup from "../pages/Signup";
import FAQ from "../pages/FAQ";
import Services from "../pages/Services";
import TermsAndCondition from "../pages/TermsAndCondition";
import Courses from "../pages/Courses";
import Career from "../pages/Career/Career";
import Policy from "../pages/Policy";
import PageNotFound from "../pages/404/PageNotFound";
import Blogs from "../pages/Blogs/Blogs";
import CompTIAsinglePage from "../pages/SinglePage/CompTIA/CompTIAsinglePage";
import ReviewPage from "../pages/review/ReviewPage";
// import BookPage from "../pages/ebook/BookPage";
import LandingPage from "../pages/landingPage/LandingPage";
import Employee from "../pages/Employee";
import Internship from "../pages/Internship";
import Test from "../pages/Test/Test";
import Training from "../pages/Training";
import Comptia from "../pages/Comptia";
import Aws from "../pages/Aws";
import Overview from "../pages/AWS/Overview";
import AwsCertifiedNetworking from "../pages/AWS/AwsCertifiedNetworking";
import AwsCertifiedSolution from "../pages/AWS/AwsCertifiedSolution";
import AwsCertifiedCloud from "../pages/AWS/AwsCertifiedCloud";
import AwsCertifiedData from "../pages/AWS/AwsCertifiedData";
import AwsCertifiedMachine from "../pages/AWS/AwsCertifiedMachine";
import AwsCertifiedDevops from "../pages/AWS/AwsCertifiedDevops";
import AwsCertifiedDeveloper from "../pages/AWS/AwsCertifiedDeveloper";
import AwsCertifiedSysops from "../pages/AWS/AwsCertifiedSysops";
import AwsCertifiedSecurity from "../pages/AWS/AwsCertifiedSecurity";
import ComptiaSecurity from "../pages/CompTIACourse/ComptiaSecurity";
import ComptiaLinux from "../pages/CompTIACourse/ComptiaLinux";
import ComptiaProject from "../pages/CompTIACourse/ComptiaProject";
import ComptiaProject004 from "../pages/CompTIACourse/ComptiaProject004";
import ComptiaPentest from "../pages/CompTIACourse/ComptiaPentest";
import ComptiaA from "../pages/CompTIACourse/ComptiaA";
import ComptiaCloud from "../pages/CompTIACourse/ComptiaCloud";
import ComptiaServer from "../pages/CompTIACourse/ComptiaServer";
import ComptiaNetwork from "../pages/CompTIACourse/ComptiaNetwork";
import ComptiaData from "../pages/CompTIACourse/ComptiaData";
import ComptiaCloudCLO from "../pages/CompTIACourse/ComptiaCloudCLO";
import ComptiaSecurity601 from "../pages/CompTIACourse/ComptiaSecurity601";
import ComptiaCybersecurity from "../pages/CompTIACourse/ComptiaCyberscurity";
import ComptiaIT from "../pages/CompTIACourse/ComptiaIT";
import ComptiaNetwork007 from "../pages/CompTIACourse/ComptiaNetwork007";
import ComptiaAdavnce from "../pages/CompTIACourse/ComptiaAdvance";
import AdminPanel from "../pages/AdminPanel";
import CertificateTemplate from "../pages/Test/CertificateTemplate";
import PECB from "../pages/PECB";
import Computerforensics from "../pages/Pecb/Computer-forensics";
import CybersecurityAuditTrainings from "../pages/Pecb/Cybersecurity-Audit-Trainings";
import ISO9001LeadImplementer from "../pages/Pecb/ISO-9001-Lead-Implementer";
import ISO9001 from "../pages/Pecb/ISO-9001";
import ISO22301 from "../pages/Pecb/ISO-22301";
import ISO31000RiskManager from "../pages/Pecb/ISO-31000-Risk-Manager";
import ISO37001 from "../pages/Pecb/ISO-37001";
import ISO45001 from "../pages/Pecb/ISO-45001";
import Isoiec27001LeadImplementer from "../pages/Pecb/Iso-iec-27001-Lead-Implementer";
import Isoiec27001 from "../pages/Pecb/Iso-iec-27001";
import Isoiec27005RiskManager from "../pages/Pecb/Iso-iec-27005-Risk-Manager";
import Isoiec27032CyberSecurity from "../pages/Pecb/Iso-iec-27032-Cyber-Security";
import PecbCertifiedIso27001Foundation from "../pages/Pecb/Pecb-Certified-Iso-27001-Foundation";
import SCADASecurityManager from "../pages/Pecb/SCADA-Security-Manager";
import VerifyCertificate from "../pages/Test/VerifyCertificate";
import ServiceDetail from "../pages/ServiceDetail";


const AllRoute = () => {
  const token = localStorage.getItem("token");

  return (
    <Routes>
      <Route path="/review-page" element={<ReviewPage />} />
      <Route path="/" element={<Home />} />
      {/* <Route path="/ebook-page" element={<BookPage />} /> Allow access without token */}

      <Route path="/login" element={!token ? <Login /> : <Navigate to="/" replace />} />
      <Route path="/signup" element={!token ? <Signup /> : <Navigate to="/" replace />} />

      <Route path="/about-us" element={<AboutUS />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/frequently-asked-questions" element={<FAQ />} />
      <Route path="/our-services" element={<Services />} />
      <Route path="/Terms-and-Conditions" element={<TermsAndCondition />} />

      <Route path="/Courses-details" element={<Courses />} /> {/* Allow access without token */}

      <Route path="/Career-details" element={<Career />} />
      <Route path="/Our-Policies" element={<Policy />} />
      <Route path="/Our-Blogs" element={<Blogs />} />
      <Route path="*" element={<PageNotFound />} />
      <Route path="/admin" element={<AdminPanel />} />
      <Route path="/CompTIA-single-page" element={<CompTIAsinglePage />} />
      <Route path="/home" element={<LandingPage />} />
      <Route path="/employee" element={<Employee />} />
      <Route path="/internship" element={<Internship />} />
      <Route path="/test" element={<Test />} />
      <Route path="/training" element={<Training />} />
      <Route path="/training-comptia" element={<Comptia />} />
      <Route path="/aws" element={<Aws />} />
      <Route path="/awsSecurity" element={<AwsCertifiedSecurity />} />
      <Route path="/awsSysops" element={<AwsCertifiedSysops />} />
      <Route path="/awsDeveloper" element={<AwsCertifiedDeveloper />} />
      <Route path="/awsDevops" element={<AwsCertifiedDevops />} />
      <Route path="/awsMachine" element={<AwsCertifiedMachine />} />
      <Route path="/awsData" element={<AwsCertifiedData />} />
      <Route path="/awsCloud" element={<AwsCertifiedCloud />} />
      <Route path="/awsSolution" element={<AwsCertifiedSolution />} />
      <Route path="/awsNetworking" element={<AwsCertifiedNetworking />} />
      <Route path="/overview" element={<Overview />} />
      <Route path="/comptiaSecurity" element={<ComptiaSecurity />} />
      <Route path="/comptiaLinux" element={<ComptiaLinux />} />
      <Route path="/comptiaProject" element={<ComptiaProject />} />
      <Route path="/comptiaProject004" element={<ComptiaProject004 />} />
      <Route path="/comptiaPentest" element={<ComptiaPentest />} />
      <Route path="/comptiaA" element={<ComptiaA />} />
      <Route path="/comptiaCloud" element={<ComptiaCloud />} />
      <Route path="/comptiaServer" element={<ComptiaServer />} />
      <Route path="/comptiaNetwork" element={<ComptiaNetwork />} />
      <Route path="/comptiaData" element={<ComptiaData />} />
      <Route path="/comptiaCloudCLO" element={<ComptiaCloudCLO />} />
      <Route path="/comptiaSecurity601" element={<ComptiaSecurity601 />} />
      <Route path="/comptiaCybersecurity" element={<ComptiaCybersecurity />} />
      <Route path="/comptiaIT" element={<ComptiaIT />} />
      <Route path="/comptiaNetwork007" element={<ComptiaNetwork007 />} />
      <Route path="/comptiaAdvance" element={<ComptiaAdavnce />} />
      <Route path="/cer" element={<CertificateTemplate />} />
      <Route path="/verifyCertificate" element={<VerifyCertificate />} />
      <Route path="/PECB" element={<PECB />} />
      <Route path="Computerforensics" element={<Computerforensics />} />
      <Route path="/CybersecurityAuditTrainings" element={<CybersecurityAuditTrainings />} />
      <Route path="ISO9001LeadImplementer" element={<ISO9001LeadImplementer />} />
      <Route path="ISO9001" element={<ISO9001/>}/ >
      <Route path="ISO22301" element={< ISO22301/>}/ >
      <Route path="ISO31000RiskManager" element={<ISO31000RiskManager/>}/ >
      <Route path="ISO37001" element={<ISO37001/>}/ >
      <Route path="ISO45001" element={<ISO45001 />}/ >
      <Route path="Isoiec27001LeadImplementer" element={ <Isoiec27001LeadImplementer/>}/ >
      <Route path="Isoiec27001" element={ <Isoiec27001/>}/>
      <Route path="Isoiec27005RiskManager" element={ <Isoiec27005RiskManager/>}/ >
      <Route path="Isoiec27032CyberSecurity" element={ <Isoiec27032CyberSecurity/>}/ >
      <Route path="PecbCertifiedIso27001Foundation" element={ <PecbCertifiedIso27001Foundation/>}/ >
      <Route path="SCADASecurityManager" element={ <SCADASecurityManager/>}/ >
      <Route path="/service-detail" element={<ServiceDetail />} />
    </Routes>
  );
};

export default AllRoute;

